.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.item {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
}

.item svg {
  margin-right: 8px;
}

@media (min-width: 740px) {
  .wrap {
    display: flex;
    justify-content: center;
    grid-gap: 0;
  }

  .item {
    margin: 0 16px;
  }
}
