.scrollWrapper {
  overflow: hidden;
  background: #fff;
  position: relative;
  --height: 350px;
}

.scroll {
  /* transition: 0.05s linear; */
  transform: translateX(var(--offset));
}

.note {
  position: absolute;
  bottom: 1rem;
  text-align: center;
}

.noteIcon {
  margin: 4px;
}

.isHorizontal {
  bottom: calc(var(--height) / 2 - var(--height) * 0.01);
  display: flex;
  align-items: center;
}

.isHorizontal div {
  display: inline-block;
}

.isHorizontal .noteIcon {
  transform: rotate(-90deg) translateX(-2px);
  width: 25px;
}

.axis {
  border-top: 1px solid #292929;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  margin-bottom: 6rem;
}

.data {
  position: relative;
  height: var(--height);
}

.isHovered .outbreak {
  opacity: 0.2;
}

.outbreak {
  position: absolute;
  height: 10px;
  background: var(--color);
  transform: translateX(0px) translateY(1px);
  border: 1px solid #292929;
  transition: 0.5s ease-out;
}

.isHovered .outbreak:hover {
  opacity: 1;
}

.deaths {
  width: 1px;
  background: #292929;
  position: absolute;
  bottom: 9px;
  top: calc(var(--height) / -2 - var(--height) * 0.05 + var(--yOffset));
  left: var(--xOffset);
  transform: translateX(-1px);
}

.deaths:before,
.deaths:after {
  content: "";
  width: var(--width);
  height: var(--width);
  position: absolute;
  bottom: 100%;
  left: 50%;
  border-radius: 50%;
  background: var(--color);
  border: 1px solid #292929;
  transform: translate(-50%, 50%);
}

.deaths:before {
  width: 10px;
  height: 10px;
  background: transparent;
  border: 0;
}

.tick {
  width: 1px;
  background: #292929;
  height: 16px;
  position: absolute;
  transform: translate(0px);
  transition: 0.05s ease-out;
}

.tick:after {
}

.isLarge {
  height: 32px;
}

.isCurrent {
  height: 32px;
  /* top: calc(var(--height) * -1); */
  font-size: 18px;
  font-weight: bold;
}

.tickText {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: 8px;
  transform: translateX(-50%);
}

.tick:not(.isLarge) .tickText {
  display: none;
}
