.title {
  width: 30px;
  padding: 4px;
  font-size: 16px;
  line-height: 25px;
  color: #002e45;
  font-weight: bold;
  font-family: wellcomebold;
  position: relative;
  min-width: 40px;
  vertical-align: top;
  text-align: left;
  border: 0;
  margin: 0;
  display: flex;
}

.isButton {
  cursor: pointer;
  transition: 0.4s ease-out;
  opacity: 0.4;
}

.isButton:hover,
.isButton.isActive {
  opacity: 1;
}

.titleText {
  position: absolute;
  top: 50%;
  left: 50%;
  width: max-content;
  transform: translate(-50%, -50%) rotate(-90deg);
}

@media (min-width: 600px) {
  .title {
    width: 160px;
    padding: 8px;
    font-size: 24px;
    line-height: 32px;
  }

  .titleText {
    position: relative;
    transform: none;
    top: 0;
    left: 0;
  }
}
