.positionWrap {
  position: absolute;
  transition: 0.1s ease-out;
  pointer-events: none;
  z-index: 9;
  padding: 1rem;
}

.wrap {
  padding: 8px;
  border: 1px solid #292929;
  background: #fff;
  opacity: 0;
  width: 45vw;
  max-width: clamp(100px, 140px, 250px);
  min-width: 100px;
  transition: 0.2s ease-out;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  word-wrap: break-word;
}

.onRight {
  transform: translateX(-100%) translateX(-2rem);
}

.onBottom {
  transform: translateY(-100%);
}

.onRight.onBottom {
  transform: translateX(-100%) translateX(-2rem) translateY(-100%);
}

.center {
  transform: translateX(-50%);
}

.isVisible {
  opacity: 1;
}

.year,
.title,
.sub {
  margin: 0;
}

.year {
  font-size: 16px;
  line-height: 25px;
  font-family: wellcomebold;
}

.year:not(:last-child) {
  margin-bottom: 2px;
}

.title:not(:last-child) {
  margin-bottom: 2px;
}

@media (min-width: 600px) {
  .wrap {
    padding: 16px;
    width: 45vw;
    max-width: clamp(100px, 200px, 250px);
    min-width: 100px;
    font-size: 16px;
    line-height: 25px;
  }

  .year {
    font-size: 18px;
    line-height: 24px;
  }

  .year:not(:last-child) {
    margin-bottom: 4px;
  }
}
