.wrap {
  background: #f2f2f2;
  color: #292929;
  padding: 32px 8px;
  /* margin: 1rem; */
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.header {
  text-align: center;
  /* margin-bottom: 32px; */
}

.title {
  font-weight: bold;
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
  max-width: 1109px;
  margin-left: auto;
  margin-right: auto;
  font-family: wellcomebold;
}

.sub {
  font-size: 24px;
  line-height: 38px;
  margin: 0;
  margin-bottom: 24px;
}

.interaction,
.legend {
  margin-bottom: 16px;
  max-width: 1109px;
  margin-left: auto;
  margin-right: auto;
}

.cta {
  padding: 12px;
  background: #e6e6e6;
  margin: 0;
  margin-left: -8px;
  margin-right: -8px;
  font-size: 16px;
  line-height: 25px;
}

.interaction,
.legend,
.cta {
  margin-bottom: 16px;
}

.chart {
  margin-bottom: 48px;
  max-width: 1109px;
  margin-left: auto;
  margin-right: auto;
}

.sources,
.notes {
  margin: 0;
  font-size: 12px;
  line-height: 19px;
  max-width: 768px;
}

.sources:not(:last-child),
.notes:not(:last-child) {
  margin-bottom: 12px;
}

.footer {
  border-top: 1px solid #292929;
  padding-top: 24px;
  /* display: flex;
  align-items: flex-start;
  justify-content: space-between; */
  max-width: 1109px;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  display: inline-block;
  float: right;
  padding-left: 4px;
  padding-bottom: 4px;
}

@media (min-width: 600px) {
  .wrap {
    padding: 64px;
  }

  .footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .logo {
    float: none;
    padding-left: 32px;
  }

  .cta {
    padding: 8px;
    margin-left: -64px;
    margin-right: -64px;
    margin-bottom: 3;
  }

  .title {
    font-size: 32px;
    line-height: 51px;
    margin-bottom: 24px;
  }

  .sources,
  .notes {
    font-size: 14px;
    line-height: 22px;
  }
}
