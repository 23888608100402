.wrap {
}

.title {
  font-weight: bold;
}

.items {
  display: flex;
  grid-gap: 8px 12px;
  justify-content: center;
  font-size: 14px;
  line-height: 25px;
  flex-wrap: wrap;
}

.item {
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  text-align: left;
}

.item:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #292929;
  margin-right: 4px;
  background: var(--color);
}

@media (min-width: 500px) {
  .items {
    display: grid;
    grid-gap: 24px;
    grid-auto-columns: max-content;
    grid-template-columns: none;
    grid-auto-flow: column;
  }
}

@media (min-width: 600px) {
  .items {
    font-size: 16px;
  }

  .item:before {
    margin-right: 8px;
  }
}
