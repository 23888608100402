.stage {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 0;
}

.buttons {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
  grid-gap: 16px;
}

.chart {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 12px 12px;
}

.container {
  max-width: min(60vh, 400px);
  flex: 1;
}

.wrap {
  padding-bottom: 100%;
  height: 0;
  position: relative;
  margin: 12px 0;
  z-index: 1;
}

.cases,
.deaths {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(var(--size) * 100%);
  height: calc(var(--size) * 100%);
  border-radius: 50%;
  background: #fff;
  border: 1px solid #292929;
  transform: translate(-50%, -50%);
  /* border: 1px solid #000; */
  overflow: hidden;
  transition: 0.4s ease-out;
  min-width: 8px;
  min-height: 8px;
}

.isZero {
  opacity: 0;
}

.cases:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color);
  opacity: 0.5;
}

.deaths {
  background: var(--color);
  width: calc(var(--size) * 100%);
  height: calc(var(--size) * 100%);
  /* border: 1px solid var(--color); */
  z-index: 9;
}

.wrap:before {
  position: absolute;
  content: "";
  top: -6px;
  bottom: 50%;
  left: 50%;
  width: 1px;
  background: #292929;
  opacity: 1;
  z-index: -1;
}

.wrap:after {
  position: absolute;
  content: "";
  top: 50%;
  bottom: -6px;
  left: 50%;
  width: 1px;
  background: #292929;
  z-index: 1;
}

.label {
  text-align: center;
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
}

.label p {
  margin: 0;
}

.label p:first-child {
  margin-bottom: 4px;
  font-weight: bold;
}

.label P:last-child {
  font-variant-numeric: tabular-nums;
  font-family: wellcomebold;
}

.figure {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  text-transform: lowercase;
  z-index: -1;
}

.button {
}

.button.isActive,
.button:hover {
  opacity: 1;
}

.button:not(:last-child) {
  margin-bottom: 32px;
}

@media (min-width: 600px) {
  .label {
    font-size: 16px;
  }

  .figure {
    font-size: 24px;
    line-height: 38px;
  }

  .chart {
    padding: 24px 12px;
  }

  .stage {
    grid-gap: 16px;
  }
}
