.wrap {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 20px;
  position: relative;
  /* overflow: hidden; */
  /* padding: 1rem 0; */
  margin-left: auto;
  margin-right: auto;
}

.hideOutput {
  padding-bottom: 0;
}

.inputs {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
}

.inputWrap {
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
  transition: 0.4s ease-out;
}

.inputWrap:before {
  content: "";
  background: #33586a;
  position: absolute;
  top: 50%;
  left: 16px;
  right: 16px;
  height: 2px;
}

.inputWrap:after {
  content: "";

  position: absolute;
  top: 50%;
  left: 16px;
  right: 16px;
  height: 12px;
  border-left: 2px solid #33586a;
  border-right: 2px solid #33586a;
  transform: translateY(-50%) translateY(1px);
  /* pointer-events: none; */
  z-index: -1;
}

.inputs:before {
  content: "";
  background: #ddd;
  position: absolute;
  top: 50%;
  left: 16px;
  right: 16px;
  height: 2px;
}

.yearLabel {
  position: absolute;
  top: 100%;
  font-size: 20px;
  line-height: 24px;
  margin-top: 4px;
  transform: translateX(-50%);
  font-variant-numeric: tabular-nums;
  font-family: wellcomebold;
}

input[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  z-index: 1;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background: #e6e6e6;
  cursor: pointer;
  margin-top: -2px;
  margin-left: 1px;
  border: 1px solid #292929;
  transition: 0.2s ease-out;
  box-shadow: 1px 1px 18px 4px rgba(135, 135, 135, 0.5);
}

input[type="range"]:hover::-webkit-slider-thumb,
input[type="range"]:focus::-webkit-slider-thumb {
  margin-top: -5px;
  margin-left: 0;
  height: 32px;
  width: 32px;
  box-shadow: 1px 1px 18px 4px rgba(135, 135, 135, 0.8);
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  position: relative;
  height: 24px;
  cursor: pointer;
  background: transparent;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: transparent;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  position: relative;
  height: 24px;
  cursor: pointer;
  background: transparent;
  box-shadow: 1px 1px 18px 4px rgba(135, 135, 135, 0.5);
}

/* input[type="range"]:focus */
.tooltips {
  transition: 0.2s ease-out;
  opacity: 0;
}

input[type="range"]:focus + output + .tooltips,
input[type="range"]:hover + output + .tooltips {
  opacity: 1;
}

.year {
  line-height: 1;
  position: relative;
}

.year:first-child:after {
  content: "";
  position: absolute;
  left: 100%;
  margin-left: 16px;
  top: 0;
  bottom: 0;
  border-right: 2px solid #ddd;
  /* padding-right: 16px; */
  /* transform: translateX(18px); */
}

.year:last-child:after {
  content: "";
  position: absolute;
  right: 100%;
  margin-right: 16px;
  top: 0;
  bottom: 0;
  border-left: 2px solid #ddd;
}

.tick {
  position: absolute;
  transform: translateX(-50%);
  /* z-index: 2; */
}

.noHover {
  pointer-events: none;
}

.tick:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  transform: translate(-50%, -50%);
  height: 6px;
  background: #33586a;
  display: none;
}

.tick svg {
  position: relative;
}

@media (min-width: 600px) {
  .wrap {
    padding-bottom: 40px;
  }

  .yearLabel {
    font-size: 24px;
    line-height: 38px;
    margin-top: 8px;
  }
}
