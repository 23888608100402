.wrap {
  display: flex;
  position: relative;
  /* grid-gap: 1px; */
  /* background: #fff; */
}

.title {
  padding: 4px;
  font-size: 16px;
  line-height: 25px;
  color: #002e45;
  font-weight: bold;
  font-family: wellcomebold;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  pointer-events: none;
}

.showYears {
  margin-top: 28px;
}

.year {
  flex: 1;
  position: relative;
  /* background: #fff; */
  transition: 0.3s ease-out;
  min-height: 160px;
}

.year:not(.noData):hover {
  transform: scale(1.05);
  box-shadow: 1px 1px 18px 4px rgba(204, 204, 204, 0.8);
  z-index: 1;
}

.noData {
  /* opacity: 0.25; */
  background-image: linear-gradient(
    135deg,
    rgba(204, 204, 204, 0.8) 4.55%,
    rgba(204, 204, 204, 0) 4.55%,
    rgba(204, 204, 204, 0) 50%,
    rgba(204, 204, 204, 0.8) 50%,
    rgba(204, 204, 204, 0.8) 54.55%,
    rgba(204, 204, 204, 0) 54.55%,
    rgba(204, 204, 204, 0) 100%
  );
  background-size: 7.78px 7.78px;
}

.hasNoDataLabel:after {
  content: "No Data";
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
  color: #292929;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  width: max-content;
  display: none;
}

.hasNoDataLabel.isHorizontal:after {
  transform: translate(-50%, -50%) rotate(0deg);
}

.label {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: min-content;
  color: #000;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  margin-bottom: 4px;
  font-family: wellcomebold;
}

.year:last-child .label {
  left: auto;
  right: 0;
}

.infoWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(-22px);
  z-index: 2;
}

.infoWrap.odd {
  transform: translate(-50%, -50%) translateY(22px);
}

.infoWrap.middle {
  transform: translate(-50%, -50%) translateY(0px);
}

.infoWrap:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  width: 44px;
  height: 44px;
  transform: translate(-50%, -50%);
  opacity: 0;
}

@media (min-width: 600px) {
  .title {
    padding: 8px;
    font-size: 24px;
    line-height: 32px;
  }

  .label {
    font-size: 18px;
    line-height: 24px;
  }

  .hasNoDataLabel {
    display: block;
  }
}
