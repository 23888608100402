.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  margin-bottom: 8px;
  font-size: 14px;
}

.text {
  margin: 0 0.5rem;
  font-size: 14px;
}

.color {
  width: 32px;
  height: 32px;
  margin-left: 1px;
}
