@font-face {
  font-family: "wellcomebold";
  src: url("./fonts/wellcome-bold-webfont.woff2") format("woff2"),
    url("./fonts/wellcome-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 100%;
  letter-spacing: 0.4px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f2f2;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
