.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(64px, 1fr));
  grid-gap: 24px 0;
  position: relative;
  margin-top: 32px;
  padding: 0 24px;
}

.breakthrough {
  text-align: center;
  position: relative;
}

.breakthrough:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background: #292929;
}

.breakthrough svg {
  position: relative;
  z-index: 1;
  transition: 0.2s ease-out;
  border-radius: 50%;
  cursor: pointer;
}

.breakthrough svg:focus {
  outline: none;
  border: 3px solid #000;
}

.breakthrough svg:focus,
.breakthrough svg:hover {
  box-shadow: 1px 1px 18px 4px rgba(204, 204, 204, 0.8);
  transform: scale(1.05);
}

.year {
  position: absolute;
  top: -24px;
  bottom: 50%;
  left: 0px;
  transform: translateX(-50%);
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
}

.year:after {
  content: "";
  width: 1px;
  background: #292929;
  flex: 1;
}

.breakthrough[data-year]:not(:nth-child(2)):not(:nth-last-child(2)) .year {
  opacity: 1;
}

.breakthrough[data-year="1920"] ~ .breakthrough[data-year="1920"] .year,
.breakthrough[data-year="1930"] ~ .breakthrough[data-year="1930"] .year,
.breakthrough[data-year="1940"] ~ .breakthrough[data-year="1940"] .year,
.breakthrough[data-year="1950"] ~ .breakthrough[data-year="1950"] .year,
.breakthrough[data-year="1960"] ~ .breakthrough[data-year="1960"] .year,
.breakthrough[data-year="1970"] ~ .breakthrough[data-year="1970"] .year,
.breakthrough[data-year="1980"] ~ .breakthrough[data-year="1980"] .year,
.breakthrough[data-year="1990"] ~ .breakthrough[data-year="1990"] .year,
.breakthrough[data-year="2000"] ~ .breakthrough[data-year="2000"] .year,
.breakthrough[data-year="2010"] ~ .breakthrough[data-year="2010"] .year,
.breakthrough[data-year="2020"] ~ .breakthrough[data-year="2020"] .year,
.breakthrough[data-year="2030"] ~ .breakthrough[data-year="2030"] .year,
.breakthrough[data-year="2040"] ~ .breakthrough[data-year="2040"] .year {
  opacity: 0;
}

.minYear,
.maxYear {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 4px; */
  position: relative;
  font-family: wellcomebold;
}

.minYear {
  justify-content: flex-start;
  grid-column-start: 2;
}

.maxYear {
  justify-content: flex-end;
}

.maxYear:before,
.minYear:after {
  content: "";
  height: 1px;
  background: #000;
  flex: 1;
  transform: translateY(1px);
}

.minYear:after {
  margin-left: 4px;
}

.maxYear:before {
  margin-right: 4px;
}

@media (min-width: 600px) {
  .grid {
    padding: 0;
  }
}
